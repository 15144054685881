import React, {useState} from 'react';
import {NavLink} from 'react-router-dom';
import {IconMenu, Logo} from './Icons';

const colorDark = "#2D2D2D";

const Navigation = () => {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  return (
    <nav className="container-nav">
      <NavLink to='/' tabIndex="0" className="nav-left-col">
        <Logo color={colorDark} />
      </NavLink>
      <ul className={click ? "nav-centre-col nav-list mobile-menu-shown" : "nav-centre-col nav-list mobile-menu-hidden"}>
        <li onClick={closeMobileMenu}><NavLink to="/">Home</NavLink></li>
        <li onClick={closeMobileMenu}><NavLink to="/about">About</NavLink></li>
        <li onClick={closeMobileMenu}><NavLink to="/services">Services</NavLink></li>
        <li onClick={closeMobileMenu}><NavLink to="/resources">Resources</NavLink></li>
        <li onClick={closeMobileMenu}><NavLink to="/contact">Contact</NavLink></li>
      </ul>
      <div className="nav-right-col">
        <button className="button">
          <NavLink to="/services">Get Started</NavLink>
        </button>
      </div>
      <div className="mobile-menu-indicator" onClick={handleClick}>
        {click ? <IconMenu mobileMenuState="mobile-menu-opened" /> : <IconMenu mobileMenuState="" />}
      </div>
    </nav>
  );
}

export default Navigation;
