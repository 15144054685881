import React, {Fragment} from 'react';
import {LogoAnimated} from '../components/Icons';

const ComingSoon = () => {
  return (
    <Fragment>
      <header id="coming-soon">
        <LogoAnimated />
        <h4><i>STAY TUNED</i></h4>
        <h2><i>This page is coming soon</i></h2>
      </header>
    </Fragment>
  );
}

export default ComingSoon;
