import React, {Fragment} from 'react';

const Privacy = () => {
  return (
    <section className="page-section" id="terms">
      <div className="page-section-heading">
        <div>
          <h2>Privacy Policy</h2>
        </div>
      </div>
      <p>Effective Date: 01 June 2024</p>
      <p>www.helloaccessible.com (the "Site") is owned and operated by Moondust Collective.</p>
      <br />
      <h4>Purpose</h4>
      <p>
      The purpose of this privacy policy (this "Privacy Policy") is to inform users of our Site of the following: the personal data we will collect, use of collected data, who has access to the data collected, the rights of Site users, and the Site's cookie policy. This Privacy Policy applies in addition to the terms and conditions of our Site.
      </p>
      <br />
      <h4>Consent</h4>
      <p>
        By using our Site users agree that they consent to the conditions set out in this Privacy Policy, and the collection, use, and retention of the data listed in this Privacy Policy.
      </p>
      <br />
      <h4>How We Use Personal Data</h4>
      <p>
        Data collected on our Site will only be used for the purposes specified in this Privacy Policy or indicated on the relevant pages of our Site. We will not use your data beyond what we disclose in this Privacy Policy.
      </p>
      <br/>
      <h4>Who We Share Personal Data With</h4>
      <h5>Employees</h5>
      <p>
        We may disclose user data to any member of our organization who reasonably needs access to user data to achieve the purposes set out in this Privacy Policy.
      </p>
      <br />
      <h5>Other Disclosures</h5>
      <p>
        We will not sell or share your data with other third parties, except in the following cases: if the law requires it, if it is required for any legal proceeding, to prove or protect our legal rights, and to buyers or potential buyers of this company in the event that we seek to sell the company.
      </p>
      <br />
      <p>If you follow hyperlinks from our Site to another site, please note that we are not responsible for and have no control over their privacy</p>
      <br />
      <h4>How Long We Store Personal Data</h4>
      <p>
        User data will be stored until the purpose the data was collected for has been achieved. You will be notified if your data is kept for longer than this period.
      </p>
      <br />
      <h4>How We Protect Your Personal Data</h4>
      <p>
        All data is only accessible to our employees. Our employees are bound by strict confidentiality agreements and a breach of this agreement would result in the employee's termination. While we take all reasonable precautions to ensure that user data is secure and that users are protected, there always remains the risk of harm. The Internet as a whole can be insecure at times and therefore we are unable to guarantee the security of user data beyond what is reasonably practical.
      </p>
      <br />
      <h4>Children</h4>
      <p>
        We do not knowingly collect or use personal data from children under 13 years of age. If we learn that we have collected personal data from a child under 13 years of age, the personal data will be deleted as soon as possible. 
      </p>
      <br />
      <h4>How to Access, Modify, Delete, or Challenge the Data Collected</h4>
      <p>
        If you would like to know if we have collected your personal data, how we have used your personal data, if we have disclosed your personal data and to who we disclosed your personal data, or if you would like your data to be deleted or modified in any way, please contact us here:
      </p>
      <br />
      <p><a href="mailto:support@helloaccessible.com">support@helloaccessible.com</a></p>
      <p>1575 W Georgia St #200, Vancouver, BC V6G 2V3</p>
      <br />
      <h4>How to Opt-Out of Data Collection, Use or Disclosure</h4>
      <p>
        In addition to the method(s) described in the How to Access, Modify, Delete, or Challenge the Data Collected section, we provide the following specific opt-out methods for the forms of collection, use, or disclosure of your personal data: You can opt-out of the use of your personal data for marketing emails. You can opt-out by clicking "unsubscribe" on the bottom of any marketing email.
      </p>
      <br />
      <h4>Cookie Policy</h4>
      <p>
        A cookie is a small file, stored on a user's hard drive by a website. Its purpose is to collect data relating to the user's browsing habits. You can choose to be notified each time a cookie is transmitted. You can also choose to disable cookies entirely in your internet browser, but this may decrease the quality of your user experience. We do not use cookies on our Site.
      </p>
      <br />
      <h4>Modifications</h4>
      <p>
        This Privacy Policy may be amended from time to time in order to maintain compliance with the law and to reflect any changes to our data collection process. When we amend this Privacy Policy we will update the "Effective Date" at the top of this Privacy Policy. We recommend that our users periodically review our Privacy Policy to ensure that they are notified of any updates. If necessary, we may notify users by email of changes to this Privacy Policy.
      </p>
      <br />
      <h4>Contact Details</h4>
      <p>
        Please contact us if you have any questions or concerns. Our contact details are as follow: 
      </p>
      <br />
      <p><a href="mailto:support@helloaccessible.com">support@helloaccessible.com</a></p>
      <p>1575 W Georgia St #200, Vancouver, BC V6G 2V3</p>
    </section>
  );
}

export default Privacy;
