import React from 'react';
import {NavLink} from 'react-router-dom';
import {IconOpenLink, LogoEst} from './Icons';

const FooterNew = () => {
    return (
        <footer>
            <div />
            <div className="new-footer-left">
                <div className="new-footer-top">
                    
                    <h6><NavLink to="/">Home</NavLink></h6>
                    <h6><NavLink to="/services">Services</NavLink></h6>
                    <h6><NavLink to="/resources">Resources</NavLink></h6>
                    <h6><NavLink to="/contact">Contact</NavLink></h6>
                </div>
                <hr />
                <div className="new-footer-bottom">
                    <h6>© All rights reserved</h6>
                </div>
            </div>
            <div className="new-footer-middle">
                <LogoEst />
            </div>
            <div className="new-footer-right">
                <div className="new-footer-top">
                    <h6><NavLink to="/privacy">Privacy</NavLink></h6>
                    <h6><NavLink to="/terms">Terms</NavLink></h6>
                </div>
                <hr />
                <div className="new-footer-bottom">
                    <h6><a href="mailto:support@helloaccessible.com" target="_blank">Email <IconOpenLink /></a></h6>
                    <h6><a href="https://www.linkedin.com/showcase/helloaccessible" target="_blank">LinkedIn <IconOpenLink /></a></h6>
                    <h6><a href="https://www.instagram.com/helloaccessible" target="_blank">Instagram <IconOpenLink /></a></h6>
                </div>
            </div>
            <div />
        </footer>
    );
}

const Footer = () => <FooterNew />;

export default Footer;