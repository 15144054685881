import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes
} from 'react-router-dom';
import ScrollToTop from "react-scroll-to-top";
import Navigation from './components/Navigation';
import Home from './pages/Home';
import ComingSoon from './pages/ComingSoon';
import Terms from './pages/Terms';
import Privacy from './pages/Privacy';
import Footer from './components/Footer';

const App = () => {
  return (
    <Router>
      <Navigation />
      <Routes>
        <Route path="/" element={<Home />}/>
        <Route path="/about" element={<ComingSoon />}/>
        <Route path="/services" element={<ComingSoon />}/>
        <Route path="/resources" element={<ComingSoon />}/>
        <Route path="/contact" element={<ComingSoon />}/>
        <Route path="/terms" element={<Terms />}/>
        <Route path="/privacy" element={<Privacy />}/>
      </Routes>
      <Footer />
      <ScrollToTop smooth color="#000000" />
    </Router>
  );
}

export default App;
